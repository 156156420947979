<template>
  <div class="wallet">
    <div class="mine-title">
      {{ $t("route.wallet") }}
    </div>
    <a-spin :spinning="isLoading" size="large">
      <div class="account">
        <a-row :gutter="[20, 10]">
          <a-col :xs="24" :sm="24" :md="16" :lg="12" :xl="10">
            <div class="wallet-card">
              <img src="../assets/wallet.png" alt="" srcset="" />
              <div class="content">
                <div>
                  <div class="wallet-title flex-center-between">
                    <span class="flex-center-start">
                      <span>{{ $t("wallet.balance") }} ({{ currency }})</span>
                      <span class="flex" @click="isBalanceVisible=!isBalanceVisible">
                        <a-icon :type="isBalanceVisible?'eye':'eye-invisible'" theme="filled" />
                      </span>
                    </span>
                    <span class="flex" @click="handleRefreshBalance">
                      <a-icon type="redo" :spin="isRefresh"/>
                    </span>
                  </div>
                  <div class="balance text-overflow-example">
                    <span v-show="isBalanceVisible">{{ currency }}</span>
                    <span>{{ balance }}</span>
                   </div>
                </div>
                <div class="wallet-text-wrapper flex-center-between">
                  <div class="wallet-subtitle" @click="handleModalVisible(1)">{{ $t("wallet.card_expenses") }}</div>
                  <a-divider type="vertical" />
                  <div class="wallet-subtitle" @click="handleModalVisible(2)">{{ $t("wallet.card_receipts") }}</div>
                  <a-divider type="vertical" />
                  <div class="wallet-subtitle"> 
                    <router-link to="/topuprules">{{ $t("wallet.card_rules") }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="subtitle">{{ $t("wallet.subtitle") }}</div>
      <a-row :gutter="[20, 10]">
        <a-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          v-for="item in rechargeList"
          :key="item.id"
          @click="(recharge.rechar_id = item.id), (recharge.price = item.price)"
        >
          <div class="quota" :class="{ 'quota-active': recharge.rechar_id === item.id }">
            <div class="price">{{ currency }} {{ item.price }}</div>
            <div class="give">
              {{ $t("wallet.give") }} {{ currency }} {{ item.give_money }}
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="subtitle">{{ $t("common.pay_4") }}</div>
      <div class="payment-wrapper">
        <div class="payment-info" v-for="(group, i) in info.payment" :key="i">
          <div :class="{ 'payment-app': i > 0 }">
            <div class="payment-title">{{ group.group }}</div>
            <div class="tag-wrapper">
              <div
                class="tag-item"
                v-for="(item, index) in group.list"
                :key="index"
                @click="onSelectPayment(item)"
                :class="{ 'tag-active': payment === item.payment }"
              >
                <input type="radio" :checked="payment === item.payment" />
                <img class="tag-icon" :src="item.img" alt="" srcset="" />
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="checkout-footer flex-center-end">
        <div class="detailed">
          <div class="agreement flex-center-start">
            <div class="radio flex">
              <input type="radio" :checked="isConsent" @click="isConsent = !isConsent"/> 
            </div>
            <div>
              <span @click="isConsent = !isConsent">{{$t('wallet.consent')}}</span> 
              <router-link to="/topuprules">{{$t('wallet.services_agreement')}}</router-link>
            </div>
          </div>
          <div>{{ $t("common.pay_5") }}<span>{{ currency }} {{ recharge.price }}</span></div>
        </div>
        <a-button 
          type="buynow" 
          :disabled="!payment" 
          :loading="isLoading" 
          @click="handleCreateOrder"
          >
          {{$t('common.pay_6')}}
        </a-button>
      </div>
    </a-spin>
    <wallet-transaction-modal 
      :visible="expenditureModalVisible"
      :type="expenditureModalType"
      :currency="currency" 
      @close="expenditureModalVisible = false"
    />
    <pay-modal
    type="recharge"
    @handle="handlePayModalCallback"
    :visible="payModalVisible"
    :info="{ 
      currency,
      order_no: order_id,
      price: recharge.price,
      qrcode 
    }"/>
  </div>
</template>
<script>
import { mapState,mapGetters,mapActions } from "vuex";
import { message } from "ant-design-vue";
import PayModal from "../components/PayModal.vue";
import WalletTransactionModal from "../components/WalletTransactionModal.vue";
import { GetRechargeListAPI,CreateRechargeOrderAPI,RechargeOrderStatusAPI } from '../api/api'
export default {
  components: {
    "pay-modal": PayModal,
    "wallet-transaction-modal":WalletTransactionModal
  },
  data() {
    return {
      isConsent:false,
      isLoading:false,
      isRefresh:false,
      rechargeList: [],
      payment: "",
      recharge: {
        rechar_id: "",
        price: "",
      },
      order_id: "",
      payModalVisible: false,
      expenditureModalVisible:false,
      expenditureModalType:1,
      qrcode: {
        url: "",
        icon: "",
      },
      isBalanceVisible:false
    };
  },
  computed: {
    ...mapGetters("config", ["currency"]),
    ...mapState('user',['info']),
    balance() {
      return this.isBalanceVisible? this.info.now_money : '***';
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('user', ['GetInfo']),
    async handleRefreshBalance(){
      try {
        this.isRefresh = true
        await this.GetInfo()
        setTimeout(() => {
          this.isRefresh = false
        }, 1500);
      } catch (error) {
        this.isRefresh = false
      }
    },
    handleModalVisible(type){
      this.expenditureModalType = type
      this.expenditureModalVisible = true
    },
    async init() {
      this.isLoading = true;
      await this.GetInfo()
      const {
        data: { recharge_quota },
      } = await GetRechargeListAPI()
      this.rechargeList = recharge_quota;
      const [{ id, price }] = recharge_quota;
      this.recharge = { rechar_id: id, price };
      this.isLoading = false;
    },
    async handleCreateOrder() {
      if(!this.isConsent) return message.warning(this.$t('wallet.tips'))
      const {origin} = window.location
      const params = { 
        ...this.recharge,
        from: this.payment,
        type: 0,
        ...(this.payment === 'paypal' && { returnUrl:`${origin}/result/` }),
      };
      try {
        this.isLoading = true;
        const {data:{order_id,paypalUrl,parameter}} = await CreateRechargeOrderAPI(params)
        this.isLoading = false
        this.order_id = order_id
        if (this.payment === 'paypal') return window.location.replace(paypalUrl);
        const { code_url } = parameter
        if(!code_url||!order_id) return message.warning('error')
        this.qrcode.url = code_url 
        this.payModalVisible = true 
      } catch (error) {
        this.isLoading = false
      }
      
    },
    handlePayModalCallback(status){
      console.log(status)
      if(status === 'cancel'){
        this.payModalVisible = false
        return
      }
      if(status === 'success'){
        this.handleCheckOrder()
      }
    },
    async handleCheckOrder(){
      const { data:{paid} } = await RechargeOrderStatusAPI({order_id:this.order_id})
      if(paid === 1){
        this.payModalVisible = false
        location.reload();
        return
      }
      message.warning(this.$t('common.pay_12'))
    },
    onSelectPayment({payment,img}){
      this.payment = payment
      this.qrcode.icon = img
    },
  },
};
</script>
<style lang="scss" scoped>
.wallet {
  .account {
    user-select: none;
    margin: 40px 0;
    .wallet-card {
      position: relative;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 20px 20px 40px 20px;
        border-radius: 14px;
        color: rgba(255, 255, 255, 0.8);
        .wallet-title {
          span {
            &:first-child {
              font-size: 16px;
              margin-right: 10px;
            }
            &:last-child {
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
        .balance {
          color: rgba(255, 255, 255, 0.96);
          font-size: 46px;
        }
        .wallet-text-wrapper{
          margin-top: 10px;
        }
        .wallet-subtitle {
          cursor: pointer;
          font-size: 14px;
          padding: 0 10px;
        }
      }
    }
  }
  .quota {
    cursor: pointer;
    user-select: none;
    position: relative;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #c6c6c6;
    transition: all 0.5s ease;
    box-shadow: inset 0 0 0 0 rgba(119, 61, 225, .1);
    .price {
      color: #222222;
      font-size: 22px;
    }
    .give {
      color: $color;
      font-size: 14px;
    }
  }
  .quota-active {
    border-color: $color;
    box-shadow: inset 0 -100px 0 0 rgba(119, 61, 225, .1);
  }
  .subtitle {
    color: #222222;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    margin: 40px 0;
    border-left: 3px solid $color;
    background-color: #f0eafb;
  }
  .checkout-footer {
    padding: 20px 0;
    .detailed {
      flex: 1;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #222222;
      margin-right: 30px;
      .agreement{
        cursor: pointer;
        user-select: none;
        .radio{
          padding-top: 4px;
          margin-right: 10px;
          input[type="radio"] {
            pointer-events:auto;
          }
        }
        span{
          margin-right: 2px;
          color: #222222;
          font-size: 14px;
        }
        a{
          color: $color;
          font-size: 14px;
        }
      }
      span {
        font-size: 24px;
        color: #b50000;
      }
    }
  }
  @include respond-to("phone") {
    .subtitle {
      font-size: 16px;
      padding: 10px;
      margin: 15px 0;
    }
    .account {
      margin: 20px 0;
      .wallet-card {
        .content {
          padding: 20px 20px 30px 20px;
          .wallet-title {
            span {
              &:first-child {
                font-size: 14px;
              }
              &:last-child {
                font-size: 16px;
              }
            }
          }
          .balance {
            font-size: 40px;
            margin: 4px 0;
          }
          .wallet-subtitle {
            font-size: 12px;
          }
          @media (max-width: 320px) {
            padding: 10px 10px 20px 10px;
            .balance {
              font-size: 30px;
            }
            .wallet-subtitle {
              padding: 0 4px;
            }
          }
        }
      }
    }
    .checkout-footer {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-content: center;
      .detailed {
        margin-right: 10px;
        font-size: 14px;
        flex-direction: column-reverse;
        .agreement{
          margin-top: 6px;
          span{
            font-size: 12px;
          }
          a{
            font-size: 12px;
          }
        }
        span {
          font-size: 18px;
        }
      }
    }
  }
  @include respond-to("pad") {
    .checkout-footer{
      display: flex;
      justify-content: space-between;
      align-content: center;
      .detailed {
        flex-direction: column-reverse;
      }
    }
  }
  @include respond-to("notebook") {
    .checkout-footer{
      display: flex;
      justify-content: space-between;
      align-content: center;
      .detailed {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
