<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :width="400"
    :title="$t('wallet.transaction_details')"
    @cancel="handleCancel"
    wrapClassName="wallet-expenditure-Modal"
  >
    <div class="tabs">
			<input type="radio" id="radio-1" name="tabs" :checked="pagination.type === 1" @change="onChangeTabHandler(1)"/>
			<label class="tab" for="radio-1">{{$t('wallet.expenses')}}</label>
      <input type="radio" id="radio-2" name="tabs" :checked="pagination.type === 2" @change="onChangeTabHandler(2)"/>
			<label class="tab" for="radio-2">{{$t('wallet.receipts')}}</label>
			<span class="glider"></span>
		</div>
		<a-spin :spinning="isLoading" size="large">
			<div class="tab-content">
				<div class="order-item" v-for="item in orders" :key="item.id">
					<div class="order-info">
						<div class="order-img">
							<img width="30" :src="item.icon" alt="" srcset="">
						</div>
						<div>
							<div class="order-text-bold">{{item.title}}</div>
							<div class="order-text">{{item.add_time}}</div>
						</div>
					</div>
					<div class="order-amount">
						<div class="order-text-bold order-text-end">{{item.pm ? '+' : '-'}} {{ currency }} {{item.number}}</div>
					</div>
				</div>
			</div>
			<div class="pagination flex" v-show="!isEmpty">
				<a-pagination 
					simple 
					@change="handlePageChange" 
					v-model="pagination.current" 
					:defaultPageSize="pagination.pageSize" 
					:total="pagination.total" 
				/>
			</div>
			<div v-show="isEmpty" class="empty-wrapper flex">
				<a-empty :image="require('@/assets/order_none.png')">
					<span slot="description" class="empty-text"> {{$t('mine.order_10')}} </span>
				</a-empty>
			</div>
		</a-spin>
  </a-modal>
</template>
<script>
import { WalletOrderListAPI } from '../api/api'
import { Pagination } from 'ant-design-vue';
export default {
  name:'WalletTransactionModal',
	components: {
    [Pagination.name]:Pagination,
  },
  props:{
		type:{
			type:Number,
			default:1
		},
    visible:{
      type:Boolean,
      default:false
    },
		currency:{
			type:String,
		}
  },
  data() {
    return {
			isLoading:false,
			orders:[],
			pagination:{
				type:1,
        current: 1,
        pageSize: 5,
        total:0
      },
    }
  },
	computed:{
		isEmpty(){
      return this.orders.length <= 0
    },
	},
	watch:{
		visible(val){
		  if(val){
				this.pagination.type = this.type
				this.init()
			}
		}
	},
  methods: {
		init(){
      this.getOrders()
    },
		async getOrders(){
      this.isLoading = true
      const { pageSize,type } = this.pagination
      const PARMS = { limit:pageSize,page:1,type };
      const { data } = await WalletOrderListAPI(PARMS)
      this.isLoading = false
      this.pagination.total = data.count
      this.orders = data.list
    },
		async handlePageChange(current, pageSize){
      this.isLoading = true
      this.pagination.current = current
      const PARMS = { limit:pageSize,page:current,type:this.pagination.type };
      const { data } = await WalletOrderListAPI(PARMS)
      this.isLoading = false
      this.orders = data.list
    },
		onChangeTabHandler(type){
      this.pagination = {current: 1,pageSize: 5,total:0,type}
      this.getOrders()
    },
    handleCancel(){
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.wallet-expenditure-Modal{
	.empty-wrapper{
		min-height: 40vh;
	}
	.tabs {
		width: 100%;
		height: 48px;
		display: flex;
		align-items: center;
		position: relative;
		background-color: #F3F3F3;
		border-radius: 16px;
		* {
			z-index: 2;
		}
		.glider {
			position: absolute;
			display: flex;
			height: 32px;
			width: calc(100%/2.3);
			z-index: 1;
			border-radius: 8px;
			transition: 0.25s ease-out;
			background-color: $color;
		}
		.tab {
			user-select: none;
			cursor: pointer;
			color: #222222;
			font-size: 14px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(100%/2);
			height: 100%;
			transition: color 0.15s ease-in;
		}
		input[type="radio"] {
			display: none;
			&:checked {
				& + label {
					color: #fff;
				}
			}
		}
		input[id="radio-1"] {
			&:checked {
				& ~ .glider {
					transform: translateX(7%);
				}
			}
		}
		input[id="radio-2"] {
			&:checked {
				& ~ .glider {
					transform: translateX(122%);
				}
			}
		}
	}
	.tab-content{
		margin: 14px 0;
		.order-item{
			display: flex;
			justify-content: space-between;
			padding: 10px 0 14px 0;
			border-bottom: 1px solid #B5B5B5;
			.order-text-bold{
				color: #222222;
				font-size: 14px;
				font-weight: 600;
			}
			.order-text{
				color: #999999;
				font-size: 12px;
			}
			.order-text-end{
				text-align: end;
			}
			.order-amount{
				flex: 1;
			}
			.order-info{
				flex: 1;
				display: flex;
				.order-img{
					margin-right: 10px;
					img{
						border-radius: 4px;
					}
				}
			}
			
		}
		
	}
}

</style>